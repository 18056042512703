<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>商户新增</span>
      </div>
      <div>
        <el-form :inline="false" class="form" style="text-align: left;" label-width="250px" :model="item" :rules="rules"
                 ref="form">
          <el-tabs v-model="activeName">
            <el-tab-pane label="账号信息" name="account">
              <el-form-item label="用户名" class="form-item" prop="username">
                <el-input v-model="item.username" placeholder="请填写用户名" size="small"></el-input>
              </el-form-item>

              <el-form-item label="密码" class="form-item" prop="password">
                <el-input :type="passwordType" v-model="item.password" placeholder="请填写密码" size="small"
                          auto-complete="off">
                  <i class="el-icon-view el-input__icon"
                     slot="suffix"
                     @click="handleIconClick">
                  </i>
                </el-input>
              </el-form-item>
            </el-tab-pane>

            <el-tab-pane label="商户信息" name="merchant">
              <el-form-item label="商户Logo" class="form-item" prop="merLogo">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="afterReadLogo"
                    :limit="1"
                    :file-list="logoList"
                    :multiple="false"
                    :on-remove="handleRemove">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="商户名" class="form-item" prop="merName">
                <el-input v-model="item.merName" placeholder="请填写商户名" size="small"></el-input>
              </el-form-item>

              <el-form-item label="商户类型" class="form-item" prop="merType">
                <el-select v-model="item.merType" clearable filterable @change="showChange">
                  <!--                  <el-option  value="1" label="个人商户"></el-option>-->
                  <el-option value="2" label="企业"></el-option>
                  <el-option value="3" label="个体工商户"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="label1" class="form-item" prop="companyName">
                <el-input v-model="item.companyName" :placeholder="placeholder1" size="small"></el-input>
              </el-form-item>

              <el-form-item :label="label2" class="form-item" prop="companyCertType">
                <el-select v-model="item.companyCertType" clearable filterable :placeholder="placeholder2">
                  <el-option value="U" label="营业执照"></el-option>
                  <el-option value="Y" label="组织机构代码证"></el-option>
                  <el-option value="V" label="统一社会信用代码"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="label3" class="form-item" prop="companyCertNo">
                <el-input v-model="item.companyCertNo" :placeholder="placeholder3" size="small"></el-input>
              </el-form-item>
              <el-form-item label="商户证件照片" class="form-item" prop="companyCertPhoto">
                  <el-upload
                      action=""
                      list-type="picture-card"
                      :http-request="afterReadLogo2"
                      :limit="1"
                      :file-list="companyCertPhotoList"
                      :multiple="false"
                      :on-remove="handleRemove2">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail"
                           :src="file.url" alt="">
                      <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove2(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                    </div>
                  </el-upload>
              </el-form-item>

              <!--              <el-form-item label="商户类别" class="form-item" prop="type">-->
              <!--                <el-select v-model="item.type"  clearable filterable >-->
              <!--                  <el-option  value="2" label="寺庙"></el-option>-->
              <!--                  <el-option  value="3" label="商户"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->

              <el-form-item label="商铺地址" class="form-item" prop="merAddress">
                <el-input v-model="item.merAddress" placeholder="请填写商户地址" size="small"></el-input>
              </el-form-item>

              <el-form-item label="手续费率(%)" class="form-item" prop="rate">
                <el-input v-model="item.rate" placeholder="请填写手续费率" size="small"></el-input>
                <span style="color: red;font-size: 12px;">输入1则代表手续费率为1%</span>
              </el-form-item>

              <el-form-item label="抽成比例（%）" class="form-item" prop="parentRate">
                <el-input v-model="item.parentRate" placeholder="请填写抽成比例" size="small"></el-input>
                <span style="color: red;font-size: 12px;">输入1则代表抽成比例为1%</span>
              </el-form-item>

              <el-form-item label="清分账期(天)" class="form-item" prop="intoAccountDuration">
                <el-input-number v-model="item.intoAccountDuration" :min="1" label="输入1则代表清分账期为1天"></el-input-number>
                <span style="color: red;font-size: 12px;padding-left: 10px">输入1则代表清分账期为1天</span>
              </el-form-item>
              <el-form-item label="是否自动到账" class="form-item" prop="autoReceipt">
                <el-checkbox v-model="item.autoReceipt"></el-checkbox>
              </el-form-item>


            </el-tab-pane>
            <el-tab-pane label="法人信息" name="personal">

              <el-form-item label="姓名" class="form-item" prop="merContactName">
                <el-input v-model="item.merContactName" placeholder="请填写姓名" size="small"></el-input>
              </el-form-item>

              <el-form-item label="证件类型" class="form-item" prop="certificateType">
                <el-select v-model="item.certificateType" clearable filterable>
                  <el-option value="I" label="身份证"></el-option>
                  <el-option value="P" label="护照"></el-option>
                  <el-option value="G" label="军官证"></el-option>
                  <el-option value="U" label="其他证件"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="证件号" class="form-item" prop="certificateNo">
                <el-input v-model="item.certificateNo" placeholder="请填写证件号" size="small"></el-input>
              </el-form-item>
              <el-form-item label="证件照片（正面）" class="form-item" prop="certificateNo">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="afterReadLogo3"
                    :limit="1"
                    :file-list="certificateFrontPhotoList"
                    :multiple="false"
                    :on-remove="handleRemove3">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove3(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="证件照片（反面）" class="form-item" prop="certificateNo">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="afterReadLogo4"
                    :limit="1"
                    :file-list="certificateBackPhotoList"
                    :multiple="false"
                    :on-remove="handleRemove4">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove4(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="联系电话" class="form-item" prop="merContactPhone">
                <el-input v-model="item.merContactPhone" placeholder="请填写联系电话" size="small"></el-input>
              </el-form-item>

            </el-tab-pane>
            <el-tab-pane label="银行信息" name="bank">
              <el-form-item label="银行账号" class="form-item" prop="acctNo">
                <el-input v-model="item.acctNo" placeholder="请填写银行账号" size="small"></el-input>
              </el-form-item>

              <el-form-item label="开户名" class="form-item" prop="acctName">
                <el-input v-model="item.acctName" placeholder="请填写开户名" size="small"></el-input>
              </el-form-item>

              <el-form-item label="开户行" class="form-item" prop="acctBankName">
                <el-select v-model="item.acctBankName" clearable filterable>
                  <el-option v-for="item in bank_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="开户预留电话" class="form-item" prop="acctPhone">
                <el-input v-model="item.acctPhone" placeholder="请填写开户预留电话" size="small"></el-input>
              </el-form-item>

              <el-form-item label="账户类型" class="form-item" prop="acctCardType">
                <el-select v-model="item.acctCardType" clearable filterable>
                  <el-option value="401" label="个人账户"></el-option>
                  <el-option value="601" label="对公账户"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="辅助证明材料" class="form-item" prop="certificateNo">
                <el-upload
                    action=""
                    list-type="picture-card"
                    :http-request="afterReadLogo5"
                    :limit="1"
                    :file-list="auxiliaryMaterialsPhotoList"
                    :multiple="false"
                    :on-remove="handleRemove5">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail"
                         :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                    <span  class="el-upload-list__item-delete" @click="handleRemove5(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                  </div>
                </el-upload>
              </el-form-item>

            </el-tab-pane>
          </el-tabs>
        </el-form>

        <el-row style="text-align:center;padding-top: 20px;">
          <el-button type="button" class="el-button--primary" @click="submit" style="margin-right:20px;">确定
          </el-button>
          <router-link :to="{path: `/admin/merchant_verify/index`}">
            <el-button type="button" class="el-button--default">取消</el-button>
          </router-link>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import {uuid} from "vue-uuid";
import OSS from "ali-oss";

export default {
  name: "new.vue",
  data() {
    return {
      fullscreenLoading: false,
      passwordType: 'password',
      activeName: 'account',
      logoList:[],
      companyCertPhotoList:[],
      certificateFrontPhotoList:[],
      certificateBackPhotoList:[],
      auxiliaryMaterialsPhotoList:[],
      item: {
        username: null,
        password: null,
        merLogo: null,
        merName: null,
        merType: null,
        merAddress: null,
        companyCertType: null,
        companyCertNo: null,
        companyCertPhoto: null,
        companyName: null,
        merContactName: null,
        merContactPhone: null,
        certificateType: null,
        certificateNo: null,
        certificateFrontPhoto: null,
        certificateBackPhoto: null,
        acctNo: null,
        acctName: null,
        acctPhone: null,
        acctCardType: null,
        acctBankName: null,
        type: null,
        auxiliaryMaterialsPhoto:null,
        level: localStorage.getItem("admin_level") + 1,
        parentRate: 0,
        autoReceipt: null,
        parentId: localStorage.getItem("admin_merchantId"),
        intoAccountDuration: null,
        rate: 0.6
      },
      rules: {
        username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
        password: [{required: true, message: '密码不能为空', trigger: 'blur'}],
        merName: [{required: true, message: '商户名不能为空', trigger: 'blur'}],
        merAddress: [{required: true, message: '商户地址不能为空', trigger: 'blur'}],
        companyCertNo: [{required: true, message: '个体工商户/企业证件编号不能为空', trigger: 'blur'}],
        companyName: [{required: true, message: '个体工商户/企业名称不能为空', trigger: 'blur'}],
        certificateNo: [{required: true, message: '个人证件号不能为空', trigger: 'blur'}],
        acctNo: [{required: true, message: '银行账号不能为空', trigger: 'blur'}],
        acctName: [{required: true, message: '开户名不能为空', trigger: 'blur'}],
        acctBankName: [{required: true, message: '开户行不能为空', trigger: 'blur'}],
        parentRate: [{required: true, message: '抽成比例不能为空', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确额格式,可保留两位小数'
          }],
        merContactName: [{required: true, message: '个人姓名行不能为空', trigger: 'blur'}],
        merContactPhone: [{required: true, message: '个人联系电话不能为空', trigger: 'blur'}],
        acctPhone: [{required: true, message: '开户预留号码不能为空', trigger: 'blur'}],
        merType: [{required: true, message: '商户类型不能为空', trigger: 'change'}],
        companyCertType: [{required: true, message: '个体工商户/企业证件类型不能为空', trigger: 'change'}],
        certificateType: [{required: true, message: '个人证件类型不能为空', trigger: 'change'}],
        acctCardType: [{required: true, message: '银行账号类型不能为空', trigger: 'change'}],
        // type: [{required: true, message: '商户类别不能为空', trigger: 'change'}],
        intoAccountDuration: [{required: true, message: '清分账期不能为空', trigger: 'change'}],
        merLogo: [{required: true, message: '商户Logo未上传', trigger: 'change'}],
        companyCertPhoto: [{required: true, message: '营业执照未上传', trigger: 'change'}],
        certificateFrontPhoto: [{required: true, message: '身份证（正面）未上传', trigger: 'change'}],
        certificateBackPhoto: [{required: true, message: '身份证（反面）未上传', trigger: 'change'}],
        auxiliaryMaterialsPhoto: [{required: true, message: '辅助证明材料未上传', trigger: 'change'}],
        rate: [{required: true, message: '手续费率不能为空', trigger: 'blur'}],

      },
      role_list: [],
      bank_list: [],
      label1: "个体工商户/企业名称",
      label2: "个体工商户/企业证件类型",
      label3: "个体工商户/企业证件编号",
      placeholder1: "请输入个体工商户/企业名称",
      placeholder2: "请选择个体工商户/企业证件类型",
      placeholder3: "请输入个体工商户/企业证件编号",
      logo: "",
      dialogVisible: false,
      disabled: false,
      companyCertPhotoLimit: 1,
    }
  },
  mounted() {
    this.item.id = this.$route.params.id
    this.getSTSInfo();
    this.getRoleList();
    this.getBankList();
  },
  methods: {
    getSTSInfo() {
      let _this = this
      adminHttp.get('/backend/aliyun/sts').then(result => {
        if (result && result.code === 200) {
          _this.sts = result.data;
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
      }).finally(() => {
      })
    },
    afterReadLogo(file) {
      //调用图片上传
      if (this.item.merLogo === ""||this.item.merLogo===null) {
        this.item.merLogo = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.logoList =[];
        return;
      }

      this.uploadFile(file.file, this.item.merLogo)
    },
    handleRemove(file, fileList) {
      this.logoList =[]
    },
    afterReadLogo2(file) {
      //调用图片上传
      if (this.item.companyCertPhoto === ""||this.item.companyCertPhoto===null) {
        this.item.companyCertPhoto = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.companyCertPhotoList =[];
        return;
      }

      this.uploadFile(file.file, this.item.companyCertPhoto)
    },
    handleRemove2(file, fileList) {
      this.companyCertPhotoList =[]
    },
    afterReadLogo3(file) {
      //调用图片上传
      if (this.item.certificateFrontPhoto === ""||this.item.certificateFrontPhoto===null) {
        this.item.certificateFrontPhoto = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.certificateFrontPhotoList =[];
        return;
      }

      this.uploadFile(file.file, this.item.certificateFrontPhoto)
    },
    handleRemove3(file, fileList) {
      this.certificateFrontPhotoList =[]
    },
    afterReadLogo4(file) {
      //调用图片上传
      if (this.item.certificateBackPhoto === ""||this.item.certificateBackPhoto===null) {
        this.item.certificateBackPhoto = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.certificateBackPhotoList =[];
        return;
      }

      this.uploadFile(file.file, this.item.certificateBackPhoto)
    },
    handleRemove4(file, fileList) {
      this.certificateBackPhotoList =[]
    },
    afterReadLogo5(file) {
      //调用图片上传
      if (this.item.auxiliaryMaterialsPhoto === ""||this.item.auxiliaryMaterialsPhoto===null) {
        this.item.auxiliaryMaterialsPhoto = uuid.v4() + ".jpg";
      }

      if (!this.beforeAvatarUpload(file.file)) {
        this.$message.error('上传头像图片大小不能超过 500KB!');
        this.auxiliaryMaterialsPhotoList =[];
        return;
      }

      this.uploadFile(file.file, this.item.auxiliaryMaterialsPhoto)
    },
    handleRemove5(file, fileList) {
      this.auxiliaryMaterialsPhotoList =[]
    },
    uploadFile(file, fileName) {
      const headers = {
        // 指定该Object被下载时网页的缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        // 'Content-Disposition': 'oss_download.txt',
        // 指定该Object被下载时的内容编码格式。
        // 'Content-Encoding': 'UTF-8',
        // 指定过期时间。
        // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
        // 指定Object的存储类型。
        // 'x-oss-storage-class': 'Standard',
        // 指定Object的访问权限。
        'x-oss-object-acl': 'private',
        // 设置Object的标签，可同时设置多个标签。
        // 'x-oss-tagging': 'Tag1=1&Tag2=2',
        // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'false',
      }
      let data = file;
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: this.sts.credentials.accessKeyId,
        accessKeySecret: this.sts.credentials.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: this.sts.credentials.securityToken,
        // 填写Bucket名称。
        bucket: 'heshangjr-development'
      });
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const result = client.put(
            fileName,
            data,
            {headers}
        )
        console.log(result)
      } catch (e) {
        console.log(e);
      }
    },beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024  <= 500;
      return isLt2M;
    },
    showChange(value) {
      if (value === '2') {
        this.label1 = '企业名称';
        this.label2 = '企业证件类型';
        this.label3 = '企业证件编号';
        this.placeholder1 = '请输入企业名称';
        this.placeholder2 = '请选择企业证件类型';
        this.placeholder3 = '请输入企业证件编号';
      } else if (value === '3') {
        this.label1 = '个体工商户名称';
        this.label2 = '个体工商户证件类型';
        this.label3 = '个体工商户证件编号';
        this.placeholder1 = '请输入个体工商户名称';
        this.placeholder2 = '请选择个体工商户证件类型';
        this.placeholder3 = '请输入个体工商户证件编号';
      } else {
        this.label1 = "个体工商户/企业名称";
        this.label2 = "个体工商户/企业证件类型";
        this.label3 = "个体工商户/企业证件编号";
        this.placeholder1 = "请输入个体工商户/企业名称";
        this.placeholder2 = "请选择个体工商户/企业证件类型";
        this.placeholder3 = "请输入个体工商户/企业证件编号";
      }
    },
    handleIconClick() {
      return this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    submit() {
      let _this = this;
      let params = {
        username: _this.item.username,
        merLogo: _this.item.merLogo,
        merName: _this.item.merName,
        merType: _this.item.merType,
        merAddress: _this.item.merAddress,
        companyCertType: _this.item.companyCertType,
        companyCertNo: _this.item.companyCertNo,
        companyCertPhoto: _this.item.companyCertPhoto,
        companyName: _this.item.companyName,
        merContactName: _this.item.merContactName,
        merContactPhone: _this.item.merContactName,
        certificateType: _this.item.certificateType,
        certificateNo: _this.item.certificateNo,
        certificateFrontPhoto: _this.item.certificateFrontPhoto,
        certificateBackPhoto: _this.item.certificateBackPhoto,
        auxiliaryMaterialsPhoto:_this.item.auxiliaryMaterialsPhoto,
        acctNo: _this.item.acctNo,
        acctName: _this.item.acctName,
        acctPhone: _this.item.acctPhone,
        acctCardType: _this.item.acctCardType,
        acctBankId: _this.item.acctBankName,
        type: _this.item.type,
        level: _this.item.level,
        parentRate: _this.item.parentRate,
        autoReceipt: _this.item.autoReceipt,
        parentId: _this.item.parentId,
        admin_id: localStorage.getItem("admin_id"),
        password: _this.$md5(_this.item.password),
        intoAccountDuration: _this.item.intoAccountDuration,
        rate: _this.item.rate
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.fullscreenLoading = true
          adminHttp.post('/backend/merchant/addMerchant', params).then(result => {
            if (result.code === 200) {
              _this.$message.success('新增成功')
              _this.$router.push({path: '/admin/merchant_verify/index'})
            } else {
              _this.$message.error(result.message || '新增失败')
            }
          }).catch(error => {
            _this.$message.error('请求失败')
          }).finally(() => {
            _this.fullscreenLoading = false
          })
        }
      })
    }, getBankList() {
      let _this = this
      _this.fullscreenLoading = true
      let params = {
        page: 0,
        page_size: 1000,
      }
      adminHttp.post('/common/getBankList', params).then(result => {
        if (result && result.code === 200) {
          if (result.data !== null) {
            let data = result.data;
            _this.bank_list = data;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }, getRoleList() {
      let _this = this
      _this.fullscreenLoading = true
      let params = {
        page: 0,
        page_size: 100,
      }
      adminHttp.post('/backend/role/queryRoleList', params).then(result => {
        if (result && result.code === 200) {
          if (result.data !== null) {
            let data = result.data.content;
            for (let i = 0; i < data.length; i++) {
              let obj = {
                label: data[i].name,
                value: data[i].id
              }
              _this.role_list.push(obj);
            }
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
